import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'chat-board', loadChildren: () => import('./component/chat-board/chat-board.module').then(m => m.ChatBoardModule) },
  { path: 'config/:cId', loadChildren: () => import('./component/check-config/check-config.module').then(m => m.CheckConfigModule) },
  { path: 'config/:cId/:jTitle/:jCity/:jState', loadChildren: () => import('./component/check-config/check-config.module').then(m => m.CheckConfigModule) },
  { path: 'config/:cId/:jTitle/:jCity/:jState/:jId', loadChildren: () => import('./component/check-config/check-config.module').then(m => m.CheckConfigModule) },
  { path: 'config/:cId/:jTitle/:jCity/:jState/:jId/:jSId', loadChildren: () => import('./component/check-config/check-config.module').then(m => m.CheckConfigModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
