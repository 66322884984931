import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetConfigParam } from './component/_shared/model/widget-config-param';
import { WorkflowService } from './component/_shared/service/workflow.service';
import { take } from 'rxjs/operators';
import { SignalRService } from './component/_shared/service/signal-r.service';
import { HubConnectionState } from '@microsoft/signalr';
import { CompanyService } from './component/_shared/service/company.service';
import { ClientStorageService } from './component/_shared/service/clientStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'chat-widget';

  constructor() { }

  ngOnInit(): void {
  }
}
